import './App.css';
import pre1 from './assets/Screenshot_1697190017.png';
import pre2 from './assets/Screenshot_1697190037.png';
import pre3 from './assets/Screenshot_1697190075.png';
function App() {
  return (
    <div className="App">
      <h1>Stooping</h1>
      <div className='img-list'>
        <div className='img-item'>
          <img src={pre1} />
        </div>
        <div className='img-item'>
          <img src={pre2} />
        </div>
        <div className='img-item'>
          <img src={pre3} />
        </div>
      </div>
      <h2><a target="_blank" href="https://apps.apple.com/us/app/stooping/id6451076560">下载地址</a></h2>
      <div className={'bottom-info'}>
        <a class="text-color" href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">	豫ICP备2021029765号-6A</a>
        <a class="text-color" href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">	豫ICP备2021029765号-2</a>
      </div>
    </div>
  );
}

export default App;
